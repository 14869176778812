import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="AppGlass">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Site Under Development</p>
        <p>Anshdevs.in</p>
      </header>
    </div>
  );
}

export default App;
